import React from 'react';
import styled from 'styled-components';

interface StandardButtonProps {
  children: React.ReactNode;
  icon?: string;
  primary?: boolean;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
}

const StandardButtonWrapper = styled.div<{ primary: boolean }>`
  border: 1px solid #073c7a;
  box-sizing: border-box;
  border-radius: 2px;

  height: 44px;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 14px;

  cursor: pointer;

  /* Dynamic styles based on the 'primary' prop */
  background: ${({ primary }) => (primary ? '#073c7a' : '#ffffff')};
  color: ${({ primary }) => (primary ? '#ffffff' : '#073c7a')};

  &:hover {
    background: ${({ primary }) => (primary ? '#052b5a' : '#f5f5f5')};
  }

  &:active {
    background: ${({ primary }) => (primary ? '#041e40' : '#e0e0e0')};
  }
`;

StandardButtonWrapper.displayName = 'StandardButtonWrapper';

const StandardButtonImage = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 10px;
`;

StandardButtonImage.displayName = 'StandardButtonImage';

export const StandardButton = ({
  children,
  icon,
  primary = false,
  onClick,
}: StandardButtonProps) => {
  return (
    <StandardButtonWrapper
      primary={primary}
      onClick={(e: React.MouseEvent<HTMLDivElement>) => {
        if (onClick) {
          onClick(e);
        }
      }}>
      {icon && <StandardButtonImage src={icon} />}
      <div>{children}</div>
    </StandardButtonWrapper>
  );
};
