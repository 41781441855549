import React, { useState } from 'react';
import { Flex } from '@react-css/flex';
import { requestReset } from '../../../api/auth';
import { Content, LoginButton, LoginCard, LoginCardBody, LoginError, LoginLabel, LoginMessage } from './styles';

export const RequestResetPage = () => {
  const [resetEmail, setResetEmail] = useState('');
  const [resetMessage, setResetMessage] = useState('');
  const [resetError, setResetError] = useState('');

  const handleResetSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await requestReset(resetEmail);
      setResetMessage('A password reset link has been sent to your email. Please check your inbox.');
      setResetError('');
    } catch (error) {
      setResetMessage('');
      setResetError('An error occurred while sending the request. Please try again.');
    }
  };

  return (
    <>
      <Content>
        <Flex justifyContent="center">
          <LoginCard>
            <LoginCardBody>
              <form onSubmit={handleResetSubmit}>
                <Flex flexDirection="column">
                  <LoginLabel htmlFor="email">Email</LoginLabel>
                  <input
                    type="email"
                    value={resetEmail}
                    className="login-input"
                    onChange={(e) => setResetEmail(e.target.value)}
                    placeholder="Enter your email"
                    required
                  />
                  <LoginButton type="submit" value="Send Reset Link" />
                </Flex>
              </form>
              {resetMessage && <LoginMessage>{resetMessage}</LoginMessage>}
              {resetError && <LoginError>{resetError}</LoginError>}
            </LoginCardBody>
          </LoginCard>
        </Flex>
      </Content>
    </>
  );
};
