import axios, { CancelTokenSource } from 'axios';
import { ProjectSubcontractor } from './projects';

const BASE_URL = process.env.REACT_APP_NEXTERA_API;

export const listObservationTypes = () => {
  return axios.get(`${BASE_URL}/observation-types`).then(r => r.data);
};

export const listObservations = (
  projectId: string,
  filters: any = {},
  cancelTokenSource?: CancelTokenSource
) => {
  return axios
    .get(`${BASE_URL}/project/${projectId}/observations`, {
      params: filters,
      cancelToken: cancelTokenSource?.token,
    })
    .then(r => r.data);
};

export const retrieveObservation = (projectId: string, observationId: number) => {
  return axios
    .get(`${BASE_URL}/project/${projectId}/observation/${observationId}`)
    .then(r => r.data);
};

export const updateObservation = (projectId: string, observationId: string, data: any) => {
  return axios
    .patch(`${BASE_URL}/project/${projectId}/observation/${observationId}`, data)
    .then(r => r.data);
};

export const listObservationStats = (
  projectId: string,
  filters?: any
): Promise<ObservationStats> => {
  return axios
    .get(`${BASE_URL}/project/${projectId}/observations/stats`, {
      params: filters,
    })
    .then(r => r.data)
    .then(d => d.data);
};

export interface ObservationType {
  category: number;
  name: string;
  description: string;
  id: number;
  order: number;
  display_in_filters: boolean;
  default_selected: boolean;
}

interface ProcessedImage {
  base_image: number;
  yolo_results: any[];
  id: number;
  registered_on: string;
  last_modified_on: string;
  ai_processed: boolean;
  processed_image_key: string;
  base_image_key: string;
  processed_image_url: string;
  base_image_url: string;
  viewpoints_image: any;
  retrain: boolean;
  reprocess: boolean;
  verified: boolean;
}

interface ObservationImage {
  user_image: string | undefined;
  processed_image: ProcessedImage;
}

export interface Observation {
  project_floor: any;
  id: number;
  public_id: string;
  project: number;
  image: ObservationImage;
  inspection?: number;
  type: ObservationType;
  labels: any[];
  verified: boolean;
  is_manual: boolean;
  subcontractor: ProjectSubcontractor;
  severity: string;
  unsafe: boolean;
  notes: string;
  registered_on: string;
  claimed: boolean;
  archived: boolean;
  localImage?: string;
}

interface ObservationStatsSeveritySummary {
  low: number;
  medium: number;
  high: number;
  life_threat: number;
}

interface ObservationStatsSummary {
  total: number;
  claimed: number;
  unclaimed: number;
  safe: number;
  unsafe: number;
  archived: number;
  severity: ObservationStatsSeveritySummary;
}

export interface ObservationStats extends ObservationStatsSummary {
  date_summary: Record<string, ObservationStatsSummary>;
}
