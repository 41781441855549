import React, { useEffect, useState } from 'react';
import { Switch, Route, Link, BrowserRouter, Redirect } from 'react-router-dom';
import styled from 'styled-components';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Flex } from '@react-css/flex';
import { ErrorBoundary } from 'react-error-boundary';

import { UserProvider, useUserContext } from '../../contexts/userContext';
import { BuildingPage } from './building_page/BuildingPage';
import { BuildingPageHeaderOutlet } from './building_page/components/BuildingPageHeaderOutlet';
import { BuildingsPage } from './buildings_page/BuildingsPage';
import { LoginPage } from './login/Login';
import { UserBlock } from '../common/UserBlock';
import { NotificationsProvider } from '../../contexts/notificationProvider';
import { BuildingProvider } from '../../contexts/buildingContext';
import { PATH_STRINGS } from '../../hooks/useGeneratedPaths';
import { AppErrorFallback } from '../common/Errors/AppErrorFallback';
import { PageViewHandler } from '../common/PageViewHandler';

import imgLogo from '../../assets/images/logo.png';
import { PermissionRoute } from '../common/PermissionRoute/PermissionRoute';
import { LoadingIndicator } from '../common/LoadingIndicator';
import { AdminZoneProvider, useAdminZoneContext } from '../../contexts/adminZoneContext';
import { AdminBuildingPage } from './admin_building_page/AdminBuildingPage';
import { NotificationSystem } from '../../contexts/NotificationSystem';
import { UserProfile } from './user_profile/UserProfile';
import { SiteWalkPage } from './site_walk/SiteWalkPage';
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { LoginRedirect } from './login/LoginRedirect';
import { RequestResetPage } from './login/RequestReset';
import { ResetPasswordPage } from './login/ResetPassword';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 5000,
      refetchOnWindowFocus: false,
    },
  },
});

interface AppProps {
  msalInstance: PublicClientApplication;
}

function App({
  msalInstance,
}: AppProps) {
  return (
    <MsalProvider
      instance={msalInstance}
    >
      <BrowserRouter>
        <ErrorBoundary
          FallbackComponent={AppErrorFallback}
          onReset={() => {
            window.location.reload();
          }}>
          <NotificationsProvider>
            <UserProvider>
              <PageViewHandler />
              <QueryClientProvider client={queryClient}>

                <AdminZoneProvider>
                  <div>
                    <Header>
                      <Flex
                        style={{ width: '100px' }}
                        alignItems={'center'}
                        justifyContent={'flex-start'}>
                        <LogoWithAdminIndicator />
                      </Flex>
                      <Switch>
                        <Route path={["/login", "/redirect", "/request-reset", "/reset-password"]} exact>
                          <></>
                        </Route>
                        <PrivateRoute path="/admin/project/:projectId" component={BuildingPageHeaderOutlet} fbLoader={true} />
                        <PrivateRoute path="/project/:projectId" component={BuildingPageHeaderOutlet} fbLoader={true} />

                        <PrivateRoute exact path="/admin/project/:projectId/floor/:floorId" fbLoader={true} component={BuildingPageHeaderOutlet} />
                        <PrivateRoute exact path="/project/:projectId/floor/:floorId" fbLoader={true} component={BuildingPageHeaderOutlet} />

                        <PrivateRoute path="/" component={UserCompanyLogo} fbLoader={true} />
                      </Switch>
                      <Flex
                        style={{ width: '125px' }}
                        alignItems={'center'}
                        justifyContent={'flex-end'}>
                        <UserBlock />
                      </Flex>
                    </Header>
                    <Content
                      id="content"
                    >
                      <Switch>
                        <Route path="/redirect" exact>
                          <LoginRedirect />
                        </Route>
                        <Route path="/login" exact>
                          <LoginPage />
                        </Route>
                        <Route path="/request-reset" exact>
                          <RequestResetPage />
                        </Route>
                        <Route path="/reset-password" exact>
                          <ResetPasswordPage />
                        </Route>
                        <PrivateRoute path={PATH_STRINGS.userProfile} component={UserProfile} />
                        <PrivateRoute path={PATH_STRINGS.siteWalk} component={SiteWalkPage} />
                        <PermissionRoute permission='project_tables.admin_zone_access' path="/admin" exact component={BuildingsPage} />
                        <PrivateRoute path="/" exact component={BuildingsPage} />

                        <PermissionRoute permission="project_tables.admin_read_project" path={PATH_STRINGS.adminProject} component={WrappedAdminBuildingPage} />
                        <PermissionRoute permission="project_tables.read_project" path={PATH_STRINGS.project} component={WrappedBuildingPage} />
                      </Switch>
                    </Content>
                  </div>
                </AdminZoneProvider>

                <NotificationSystem />
              </QueryClientProvider>
            </UserProvider>
          </NotificationsProvider>
        </ErrorBoundary>
      </BrowserRouter>
    </MsalProvider>
  );
}

const WrappedBuildingPage = () => (
  <BuildingProvider>
    <BuildingPage />
  </BuildingProvider>
);

const WrappedAdminBuildingPage = () => (
  <BuildingProvider>
    <AdminBuildingPage />
  </BuildingProvider>
);

function PrivateRoute({ component: Component, fbLoader = false, ...rest }: any) {
  const { state: authState } = useUserContext();
  if (authState.loading) return <LoadingIndicator fb={fbLoader} />;
  return (
    <Route
      {...rest}
      render={props =>
        authState.isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}

const Header = styled.div`
  height: 68px;
  background: #ffffff;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.05);

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding-left: 60px;
  padding-right: 60px;
`;

const Logo = styled.img.attrs(() => ({
  src: imgLogo,
}))`
  width: 36px;
  height: 31px;
  opacity: 0.4;
  margin-top: -1px;
`;

const LogoWithAdminIndicator = () => {
  const { inAdminZone } = useAdminZoneContext();

  return (
    <>
      <Link to="/">
        <Logo />
      </Link>

      {inAdminZone &&
        <Switch>
          <Route path='/admin'>
            <Link to="/admin">
              <span style={{ color: '#7e858e', marginLeft: '10px' }}>Admin</span>
            </Link>
          </Route>
        </Switch>
      }
    </>
  )
}

const UserCompanyLogo = () => {
  const [headerLogoUrl, setHeaderLogoUrl] = useState<string>('');
  const { state: authState } = useUserContext();

  const getSetLogoUrl = (url: string) => {
    fetch(url)
      .then(image => image.blob())
      .then(image => setHeaderLogoUrl(URL.createObjectURL(image)))
  };

  useEffect(() => {
    if (authState.account_owner && authState.account_owner.logo_url) {
      getSetLogoUrl(authState.account_owner.logo_url)
    } else if (authState.employer && authState.employer.logo_url) {
      getSetLogoUrl(authState.employer.logo_url);
    }
  }, [authState.account_owner, authState.employer]);

  return (
    <>
      {(headerLogoUrl && !authState.is_demo_account) &&
        <img
          alt="Employer Logo"
          src={headerLogoUrl}
          style={{
            height: 'auto',
            maxHeight: '30px',
            maxWidth: '150px',
          }}
        />
      }
    </>
  )
}

const Content = styled.div`
  position: absolute;
  right: 60px;
  left: 60px;
  top: 68px;
  bottom: 0;
`;
export default App;

if (!String.prototype.hasOwnProperty('capitalize')) {
  Object.defineProperty(String.prototype, 'capitalize', {
    value: function () {
      return this.charAt(0).toUpperCase() + this.slice(1);
    },
    enumerable: false,
  });
}