import React, { createContext, useCallback, useContext, useEffect, useReducer } from 'react';
import { Location } from 'history'
import { useHistory, useLocation } from 'react-router-dom';
import { introspect, validate, permissions } from '../api/auth';
import { identifyUser } from '../analytics';
import axios, { AxiosError } from 'axios';
import { useNotifications } from './notificationProvider';

let loginExpiredNotificationSent = false;

const initialState = {
  email: null,
  token: null,
  firstName: null,
  last_name: null,
  public_id: null,
  isAuthenticated: false,
  permissions: null,
  loading: true,
};

const UserContext = createContext<any>(null);

const UPDATE_USER = 'UPDATE_USER';
const RESET_USER = 'RESET_USER';
const LOGIN_USER = 'LOGIN_USER';

const userReducer = (state: any, action: any) => {
  switch (action.type) {
    case LOGIN_USER:
      return {
        ...state,
        ...action.payload,
      };
    case UPDATE_USER:
      return {
        ...state,
        ...action.payload.item,
      };
    case RESET_USER:
      let reset = { ...initialState, loading: false };
      return reset;
    default:
      return state;
  }
};

export const UserProvider = ({ children }: any) => {
  const history = useHistory();
  const { state: locationState } = useLocation<Location>();
  const [state, dispatch] = useReducer(userReducer, initialState);

  const {
    addNotification,
  } = useNotifications();

  useEffect(() => {
    const responseInterceptor = axios.interceptors.response.use(response => {
      return response;
    }, (error: AxiosError) => {
      if (error.response && error.response.status === 401) {
        if (!loginExpiredNotificationSent) {
          addNotification('Login expired', 'warning');
          loginExpiredNotificationSent = true;
        }

        dispatch({
          type: 'UPDATE_USER',
          payload: { item: { token: null, isAuthenticated: false, loading: false } },
        });
      }

      return Promise.reject(error);
    });

    return () => {
      axios.interceptors.response.eject(responseInterceptor);
    }
  }, [addNotification]);

  const checkSetToken = () => {
    let existingUser = localStorage.getItem('db_usr');
    const parsedUser = existingUser ? JSON.parse(existingUser) : null;
    const existingToken = parsedUser ? parsedUser.token : null;
    const isAnonymousPage = (window.location.pathname === '/request-reset' || window.location.pathname === '/reset-password');

    if (isAnonymousPage) {
      localStorage.removeItem('db_usr')
      dispatch({
        type: 'UPDATE_USER',
        payload: { item: { token: null, isAuthenticated: false, loading: false } },
      });
    } else if (existingToken) {
      validate(existingToken).then(valid => {
        if (valid && existingToken) {
          introspect(existingToken).then(user => {
            identifyUser(user);
            permissions(user.public_id).then(data => {
              dispatch({
                type: 'LOGIN_USER',
                payload: {
                  ...user,
                  token: existingToken,
                  isAuthenticated: true,
                  permissions: data.data,
                  loading: false,
                },
              });
            });
          });
        } else {
          localStorage.removeItem('db_usr');
          dispatch({
            type: 'UPDATE_USER',
            payload: { item: { token: null, isAuthenticated: false, loading: false } },
          });
          history.push('/login');
        }
      });
    } else {
      dispatch({
        type: 'UPDATE_USER',
        payload: { item: { token: null, isAuthenticated: false, loading: false } },
      });
    }
  };

  useEffect(() => {
    if (state.isAuthenticated) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${state.token}`;
      if (window.location.pathname.includes('login') || window.location.pathname.includes('redirect')) {
        //@ts-ignore
        let nextPath = locationState?.from?.pathname;
        if (nextPath) {
          // @ts-ignore
          history.push(locationState.from);
        } else {
          history.push('/');
        }
      }
    }
  }, [state]);

  useEffect(() => {
    checkSetToken();
  }, []);

  return <UserContext.Provider value={[state, dispatch]}>{children}</UserContext.Provider>;
};

export const useUserContext = () => {
  // @ts-ignore
  const [state, dispatch] = useContext(UserContext);

  const updateUser = useCallback((item: any) => {
    dispatch({
      type: UPDATE_USER,
      payload: {
        item,
      },
    });
  }, [dispatch]);

  const resetUser = useCallback(() => {
    dispatch({
      type: RESET_USER,
    });
  }, [dispatch]);

  return {
    updateUser,
    resetUser,
    state,
  };
};
