import React from 'react';
import styled from 'styled-components';
import { Facebook, Grid } from 'react-spinners-css';

const IndicatorFlex = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  z-index: 1;
  position: relative;
  align-items: center;
  height: 100%;
  background: #ffffff59;
`;

interface LoadingIndicatorProps {
  fb?: boolean;
  fb_center?: boolean;
  size?: number;
  containerStyle?: React.CSSProperties;
}
export const LoadingIndicator = ({
  fb = false,
  fb_center = false,
  size,
  containerStyle,
}: LoadingIndicatorProps) => {
  if (fb) return <Facebook className="didge-loading-indicator" color="#073C7A" size={20} />;
  if (fb_center) {
    return (
      <IndicatorFlex>
        <div className="didge-loading-indicator" style={{ position: 'relative', zIndex: 10 }}>
          <Facebook color="#073C7A" size={20} />
        </div>
      </IndicatorFlex>
    );
  }

  return (
    <IndicatorFlex style={containerStyle}>
      <div className="didge-loading-indicator" style={{ position: 'relative', zIndex: 10 }}>
        <Grid color="#073C7A" size={size ?? 75} />
      </div>
    </IndicatorFlex>
  );
};
