import React, { useState } from 'react';
import { Flex } from '@react-css/flex';
import { resetPassword } from '../../../api/auth';
import { Content, LoginButton, LoginCard, LoginCardBody, LoginError, LoginLabel, LoginMessage } from './styles';
import { useSearchParam } from 'react-use';

export const ResetPasswordPage = () => {
  const token = useSearchParam('token');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [resetMessage, setResetMessage] = useState('');
  const [resetError, setResetError] = useState('');

  const handleResetSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    // Validation to check if passwords match
    if (newPassword !== confirmPassword) {
      setResetMessage('')
      setResetError('Passwords do not match. Please try again.');
      return;
    }

    try {
      const result = await resetPassword(token || '', newPassword);
      if (result.meta.status === 'error') {
        setResetMessage('');
        setResetError(`Could not reset your password: ${result.data.message}`);
        return;
      }
      setResetMessage('Your password has been reset successfully.');
      setResetError('');
    } catch (error) {
      setResetMessage('');
      setResetError('An error occurred while resetting your password. Please try again.');
    }
  };

  return (
    <>
      <Content>
        <Flex justifyContent="center">
          <LoginCard>
            <LoginCardBody>
              <form onSubmit={handleResetSubmit}>
                <Flex flexDirection="column">
                  <LoginMessage>You are resetting your password, enter your new password and confirm below.</LoginMessage>
                  <LoginLabel htmlFor="email">New Password</LoginLabel>
                  <input
                    type="password"
                    value={newPassword}
                    className="login-input"
                    onChange={(e) => setNewPassword(e.target.value)}
                    placeholder="Enter your new password"
                    required
                  />
                  <LoginLabel htmlFor="email">Confirm Password</LoginLabel>
                  <input
                    type="password"
                    value={confirmPassword}
                    className="login-input"
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    placeholder="Confirm your new password"
                    required
                  />
                  <LoginButton type="submit" value="Reset Password" />
                </Flex>
              </form>
              {resetMessage && <LoginMessage>{resetMessage}</LoginMessage>}
              {resetError && <LoginError>{resetError}</LoginError>}
            </LoginCardBody>
          </LoginCard>
        </Flex>
      </Content>
    </>
  );
};
