import React, { useCallback, useEffect, useState } from 'react';
import mixpanel from 'mixpanel-browser';
import axios from 'axios';

import { ChatManager } from '../../../../../common/Chat/ChatManager';
import { AnnotationManager } from '../../../../../common/Annotation/AnnotationManager';
import { useTagContext } from '../../../../../../contexts/tagContext';
import { Tag } from '../../../../../../api/types';
import { useBuildingContext } from '../../../../../../contexts/buildingContext';
import { SafetyTagManager } from '../../../../../common/SafetyTag/SafetyTagManager';
import { useUserContext } from '../../../../../../contexts/userContext';
import { checkUserPermissions } from '../../../../../common/PermissionWrapper/PermissionWrapper';
import { ProcoreManager } from '../../../../../common/Procore/ProcoreManager';
import { useProcoreAuthContext } from '../../../../../../contexts/procoreAuthContext';
import { ViewerPosition } from '../../../types';
import { usePannellumViewer } from '../../../../../../hooks/usePannellum';

const BASE_URL = process.env.REACT_APP_SERVICES_API;

interface TagManagerProps {
  viewerId: string;
  viewerPosition: ViewerPosition;
  angleOffset: number;
  onTakeScreenshot(callback: (blob: Blob) => void): void;
}

export const TagManager = ({
  viewerId,
  viewerPosition,
  angleOffset,
  onTakeScreenshot,
}: TagManagerProps) => {
  const { state: buildingState } = useBuildingContext();
  const { state: userState } = useUserContext();
  const { hydrateTags, findSetCurrentTag, toggleDisplay, state: tagState } = useTagContext();
  const { loaded, addHotSpot, cleanTemporaryTags } = usePannellumViewer(viewerId);

  const { state: procoreAuthState } = useProcoreAuthContext();
  const { authenticationCheckComplete, isAuthenticated: isProcoreAuthenticated } = procoreAuthState;

  const projectIsLinkedToProcore =
    buildingState &&
    buildingState.projectData &&
    buildingState.projectData.procore_company_id &&
    buildingState.projectData.procore_project_id;
  const procoreTagSelected =
    tagState.tags.current?.type === 'RFI' ||
    tagState.tags.current?.type === 'PROCORE-NEW-RFI' ||
    tagState.tags.current?.type === 'PROCORE-LINK-RFI' ||
    tagState.tags.current?.type === 'PROCORE-SAVE-IMAGE';
  const showProcoreManager =
    isProcoreAuthenticated && projectIsLinkedToProcore && procoreTagSelected;

  /*
  Callback for when user clicks on a hotspot.

  Don't rely on dynamic info here, only the args passed on creation essentially.
   */
  const hospotClickHandler = useCallback(
    (event: any, args: { pitch: number; yaw: number; type: string; tagId?: number }) => {
      if (args.tagId) {
        findSetCurrentTag(args.tagId);
        mixpanel.track('Click Hotspot', {
          type: args.type,
        });
      }
    },
    [findSetCurrentTag]
  );

  const hotspotContextMenuHandler = useCallback(
    (event: MouseEvent, args: { tagId: number }) => {
      if (args.tagId) findSetCurrentTag(args.tagId, true);
    },
    [findSetCurrentTag]
  );

  const hydrate = useCallback(
    (tagsToUpdate?: number[]) => {
      return axios
        .get(`${BASE_URL}/tags/`, {
          params: {
            isProcoreAuthenticated: isProcoreAuthenticated,
            project_id: buildingState.projectId,
            point_id: buildingState.pointId,
            image_id: buildingState.imageData.data.id,
            safety: checkUserPermissions(
              userState.permissions,
              'safety_tables.read_observation',
              buildingState.projectId
            ),
          },
        })
        .then(res => {
          hydrateTags({ all: res.data });
          res.data.forEach((tag: Tag) => {
            const useDisabledStyle = tag.rfi && tag.rfi.status === 'closed';
            addHotSpot(
              [tag.pitch, tag.yaw],
              tag.type,
              hospotClickHandler,
              hotspotContextMenuHandler,
              tag.id,
              angleOffset,
              {
                disabled: useDisabledStyle,
                tagsToUpdate: tagsToUpdate,
              }
            );
          });
          return res.data;
        });
    },
    [
      buildingState.imageData.data.id,
      buildingState.pointId,
      buildingState.projectId,
      hospotClickHandler,
      hotspotContextMenuHandler,
      hydrateTags,
      viewerId,
      isProcoreAuthenticated,
      userState.permissions,
    ]
  );

  useEffect(() => {
    const onClickOutside = (event: any) => {
      if (!event.target.closest('.pnlm-ignore')) {
        toggleDisplay();
      }
      if (!event.target.closest('.oco-dialogue') && !event.target.closest('.context-menu-li')) {
        cleanTemporaryTags();
      }
    };
    document.addEventListener('click', onClickOutside);
    return () => {
      document.removeEventListener('click', onClickOutside);
    };
  }, []);

  useEffect(() => {
    if (loaded && authenticationCheckComplete) {
      hydrate().then(() => {
        let urlTag = new URLSearchParams(window.location.search).get('tag');
        if (urlTag) findSetCurrentTag(parseInt(urlTag));
      });
    }
    return;
  }, [loaded, authenticationCheckComplete, findSetCurrentTag, hydrate]);

  return (
    <div style={{ position: 'relative' }}>
      {tagState.tags.current?.type === 'CHAT' && (
        <ChatManager viewerId={viewerId} hydrate={hydrate} viewerPosition={viewerPosition} />
      )}
      {tagState.tags.current?.type === 'ANNOTATION' && (
        <AnnotationManager
          tag={tagState.current}
          viewerId={viewerId}
          hydrate={hydrate}
          viewerPosition={viewerPosition}
        />
      )}
      {tagState.tags.current?.type === 'SAFETY' && (
        <SafetyTagManager
          tag={tagState.current}
          viewerId={viewerId}
          hydrate={hydrate}
          viewerPosition={viewerPosition}
        />
      )}
      {showProcoreManager && (
        <ProcoreManager
          viewerId={viewerId}
          hydrate={hydrate}
          viewerPosition={viewerPosition}
          onTakeScreenshot={onTakeScreenshot}
        />
      )}
    </div>
  );
};
