import React from 'react';
import styled from 'styled-components';

interface VerticalPaneProps {
  children: React.ReactNode;
}

const VerticalPaneWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  gap: 3px;
  padding-right: 10px;

  ::-webkit-scrollbar {
    width: 1em;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px rgba(0, 0, 0, 0);
    margin: 10px 0;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 2px;
    margin: 10px 0;
  }
  ::-webkit-scrollbar {
    width: 5px;
  }
`;
VerticalPaneWrapper.displayName = 'VerticalPaneWrapper';

export const VerticalPane = ({ children }: VerticalPaneProps) => {
  return <VerticalPaneWrapper>{children}</VerticalPaneWrapper>;
};
