import React from 'react';
import styled from 'styled-components';

interface StandardPanelProps {
  children: React.ReactNode;
}

const StandardPanelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 100%;
`;
StandardPanelWrapper.displayName = 'StandardPanelWrapper';

export const StandardPanel = ({ children }: StandardPanelProps) => {
  return <StandardPanelWrapper>{children}</StandardPanelWrapper>;
};
