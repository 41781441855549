import React from 'react';
import styled from 'styled-components';

interface ButtonsGroupProps {
  children: React.ReactNode;
}

const ButtonsGroupWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

export const ButtonsGroup = ({ children }: ButtonsGroupProps) => {
  return <ButtonsGroupWrapper>{children}</ButtonsGroupWrapper>;
};
