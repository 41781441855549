import styled from 'styled-components';
import { ButtonsGroup } from '../../../../common/ButtonsGroup';
import { StandardButton } from '../../../../common/StandardButton';
import { StandardPanel } from '../../../../common/StandardPanel';
import { VerticalPane } from '../../../../common/VerticalPanel';
import { ObservationType } from '../../../../../api/observations';
import { FilterItem } from './FilterItem';
import { Fragment, useCallback, useEffect, useState } from 'react';

interface FilterBoxProps {
  dummy?: string;
  observationTypes: Map<string, ObservationType[]>;
  selected: Record<string, (string | number)[]>;
  selectionReset: Record<string, (string | number)[]>;
  onChange: (filters: Record<string, (string | number)[]>) => void;
}

const PaneLabel = styled.div`
  font-size: 12px;
  text-transform: uppercase;
  color: #5c6f8a;
  padding: 12px 0 6px 0;
`;
PaneLabel.displayName = 'PaneLabel';

interface FiltersGroupProps {
  nested?: boolean;
}

const FiltersGroup = styled.div<FiltersGroupProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 6px;
  ${props => props.nested && 'padding-left: 20px;'}
`;
FiltersGroup.displayName = 'FiltersGroup';

export const FilterBox = ({ observationTypes, selected, selectionReset, onChange }: FilterBoxProps) => {
  const [filterValue, setFilterValue] = useState(selected);

  const isFilterSelected = useCallback(
    (name: string, value?: string | number) => {
      return value !== undefined && filterValue[name]?.includes(value);
    },
    [filterValue]
  );

  const isGroupSelected = useCallback(
    (value: string) => {
      const categoryValues = observationTypes.get(value)?.map(current => current.id) || [];
      const selectedValues = filterValue.category.filter(current =>
        categoryValues.includes(Number(current))
      );
      return selectedValues.length === categoryValues.length;
    },
    [observationTypes, filterValue]
  );

  const handleFilterChange = (checked: boolean, name?: string, value?: string | number) => {
    if (name && value) {
      const newFilters = { ...filterValue };
      const existingFilters = newFilters[name] || [];
      if (checked) {
        newFilters[name] = [...existingFilters, value];
      } else {
        newFilters[name] = existingFilters.filter(current => current !== value);
      }
      setFilterValue(newFilters);
    }
  };

  const handleGroupChange = (checked: boolean, name?: string, value?: string | number) => {
    if (name && value) {
      const categoryKey = String(value);
      const categoryValues = observationTypes.get(categoryKey)?.map(current => current.id) || [];
      const withoutClickedCategory = filterValue.category.filter(
        current => !categoryValues.includes(Number(current))
      );
      const newFilters = { ...filterValue };
      if (checked) {
        newFilters.category = [...withoutClickedCategory, ...categoryValues];
      } else {
        newFilters.category = [...withoutClickedCategory];
      }
      setFilterValue(newFilters);
    }
  };

  const handleApplyFilters = () => {
    onChange(filterValue);
  };

  const handleResetFilters = () => {
    setFilterValue(selectionReset);
  };

  return (
    <StandardPanel>
      <ButtonsGroup>
        <StandardButton primary onClick={handleApplyFilters}>
          Apply Filter
        </StandardButton>
        <StandardButton onClick={handleResetFilters}>Reset Filter</StandardButton>
      </ButtonsGroup>
      <div>
        <hr />
      </div>
      <VerticalPane>
        <PaneLabel>Risk Levels</PaneLabel>
        <FiltersGroup>
          <FilterItem
            name="severity"
            value="SEVERITY_SAFE"
            checked={isFilterSelected('severity', 'SEVERITY_SAFE')}
            color="#2EB06C"
            onChange={handleFilterChange}>
            Safe
          </FilterItem>
          <FilterItem
            name="severity"
            value="SEVERITY_LOW"
            checked={isFilterSelected('severity', 'SEVERITY_LOW')}
            color="#F5C20C"
            onChange={handleFilterChange}>
            Low Severity
          </FilterItem>
          <FilterItem
            name="severity"
            value="SEVERITY_MEDIUM"
            checked={isFilterSelected('severity', 'SEVERITY_MEDIUM')}
            color="#EF7E46"
            onChange={handleFilterChange}>
            Medium Severity
          </FilterItem>
          <FilterItem
            name="severity"
            value="SEVERITY_HIGH"
            checked={isFilterSelected('severity', 'SEVERITY_HIGH')}
            color="#FF5252"
            onChange={handleFilterChange}>
            High Severity
          </FilterItem>
          <FilterItem
            name="severity"
            value="SEVERITY_LIFE_THREAT"
            checked={isFilterSelected('severity', 'SEVERITY_LIFE_THREAT')}
            color="#6365D9"
            onChange={handleFilterChange}>
            Life Threatening
          </FilterItem>
          <FilterItem
            name="severity"
            value="SEVERITY_NEUTRAL"
            checked={isFilterSelected('severity', 'SEVERITY_NEUTRAL')}
            color="#94A3B8"
            onChange={handleFilterChange}>
            Neutral
          </FilterItem>
        </FiltersGroup>
        <PaneLabel>Categories</PaneLabel>
        {observationTypes.size > 0 && (
          <FiltersGroup>
            {Array.from(observationTypes.entries()).map(([category, items]) => (
              <Fragment key={category}>
                <FilterItem
                  name="group"
                  value={category}
                  checked={isGroupSelected(category)}
                  onChange={handleGroupChange}>
                  {category}
                </FilterItem>
                <FiltersGroup nested>
                  {items.map(current => (
                    <FilterItem
                      key={current.id}
                      name="category"
                      value={current.id}
                      checked={isFilterSelected('category', current.id)}
                      onChange={handleFilterChange}>
                      {current.name}
                    </FilterItem>
                  ))}
                </FiltersGroup>
              </Fragment>
            ))}
          </FiltersGroup>
        )}
      </VerticalPane>
    </StandardPanel>
  );
};
