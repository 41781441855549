import { useState, useCallback, useEffect } from 'react';
import { usePannellumViewer } from '../../../../hooks/usePannellum';

export const useContextMenu = (viewerId: string) => {
  const [xPos, setXPos] = useState('0px');
  const [yPos, setYPos] = useState('0px');
  const [pitch, setPitch] = useState<number | null>(null);
  const [yaw, setYaw] = useState<number | null>(null);
  const [showMenu, setShowMenu] = useState(false);
  const [isHotspot, setIsHotspot] = useState(false);
  const { viewer, cleanTemporaryTags } = usePannellumViewer(viewerId);

  const handleContextMenu = useCallback(
    (e: any) => {
      e.preventDefault();
      setXPos(`${e.pageX}px`);
      setYPos(`${e.pageY}px`);
      setShowMenu(true);
      const clickedOnTmpTag = e.target.classList.contains('pnlm-tmp');

      if (viewer) {
        const newCoordinates = viewer.mouseEventToCoords(e);
        const newPitch = newCoordinates[0];
        const newYaw = newCoordinates[1];
        setPitch(newPitch);
        setYaw(newYaw);
      }

      if (
        e.target.classList.contains('pnlm-hotspot') &&
        !e.target.classList.contains('pnlm-TOUR') &&
        !clickedOnTmpTag
      ) {
        setIsHotspot(true);
      } else {
        setIsHotspot(false);

        if (clickedOnTmpTag) {
          cleanTemporaryTags();
        }
      }
    },
    [setXPos, setYPos, viewer]
  );

  const handleClick = useCallback(() => {
    showMenu && setShowMenu(false);
  }, [showMenu]);

  useEffect(() => {
    let masterIV = document.getElementById('image-viewer-master');
    if (masterIV) {
      masterIV.addEventListener('click', handleClick);
      masterIV.addEventListener('contextmenu', handleContextMenu);
    }
    return () => {
      if (masterIV) {
        masterIV.removeEventListener('click', handleClick);
        masterIV.removeEventListener('contextmenu', handleContextMenu);
      }
    };
  });

  return { xPos, yPos, showMenu, isHotspot, pitch, yaw };
};
