import React from 'react';
import styled from 'styled-components';
import { Checkbox } from '../../../../common/Checkbox';

interface FilterItemProps {
  annotation?: string | number;
  name?: string;
  value?: string | number;
  checked?: boolean;
  color?: string;
  children: React.ReactNode;
  onChange?: (checked: boolean, name?: string, value?: string | number) => void;
}

const FilterItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  width: 100%;
`;
FilterItemWrapper.displayName = 'FilterItemWrapper';

const Label = styled.span`
  color: #212121;
  flex-grow: 1;
  text-align: left;
  font-size: 14px;
  font-family: Roboto;
  white-space: nowrap;
  overflow: hidden;
  padding: 2px;
  text-overflow: ellipsis;
`;

const Annotation = styled.span`
  text-align: right;
  white-space: nowrap;
`;

export const FilterItem = ({
  name,
  value,
  checked,
  color,
  children,
  annotation,
  onChange,
}: FilterItemProps) => {
  const handleChange = (checked: boolean) => {
    onChange && onChange(checked, name, value);
  };
  return (
    <FilterItemWrapper>
      <Checkbox color={color} checked={checked} onChange={handleChange} />
      <Label>{children}</Label>
      {annotation && <Annotation>{annotation}</Annotation>}
    </FilterItemWrapper>
  );
};
