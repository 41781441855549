import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useBuildingContext } from '../../../../contexts/buildingContext';
import { useTagContext } from '../../../../contexts/tagContext';
import { useUserContext } from '../../../../contexts/userContext';
import { Flex } from '@react-css/flex';
import Moment from 'react-moment';
import { useNotifications } from '../../../../contexts/notificationProvider';
import {
  AnnotationContainer,
  AnnotationForm,
  AnnotationMessage,
  LastUpdateMessage,
} from './styles';
import mixpanel from 'mixpanel-browser';
import { useImageViewerContext } from '../../../views/image_viewer/imageViewerContext';

const BASE_URL = process.env.REACT_APP_SERVICES_API;

interface IAnnotationInputProps {
  isNew: boolean;
  setEdit: any;
  text: string;
  hydrate: any;
  setText: any;
}

const AnnotationInput = ({ isNew, setEdit, text, hydrate, setText }: IAnnotationInputProps) => {
  const { state: userState } = useUserContext();
  const { state: buildingState } = useBuildingContext();
  const { state: ImageViewerState } = useImageViewerContext();
  const { findSetCurrentTag, state: tagState } = useTagContext();
  const { addNotification } = useNotifications();
  const [tmpText, setTmpText] = useState<string>(text);

  const createNewTag = () => {
    axios
      .post(`${BASE_URL}/tags/`, {
        creator: userState.public_id,
        pitch: tagState.tags.current.pitch,
        yaw: tagState.tags.current.yaw + ImageViewerState.master.data.angle,
        project_id: buildingState.projectId,
        point_id: buildingState.pointId,
        floor_id: buildingState.floorId,
        image_id: buildingState.imageData.data._id,
        type: 'ANNOTATION',
        message: tmpText,
        last_edited_by: userState.public_id,
      })
      .then(res => {
        hydrate().then(() => {
          findSetCurrentTag(res.data.id);
        });
        setText(tmpText);
        setEdit(false);
        mixpanel.track('Create Annotation');
      });
  };

  const updateTag = () => {
    axios
      .patch(`${BASE_URL}/tags/annotations/${tagState.tags.current.annotation.id}/`, {
        message: tmpText,
        last_edited_by: userState.public_id,
      })
      .then(() => {
        hydrate();
        setText(tmpText);
        setEdit(false);
        mixpanel.track('Update Annotation');
      });
  };

  const onSubmit = (e: any) => {
    e.preventDefault();
    if (!tmpText.trim()) {
      addNotification('Annotation can not be blank', 'error');
      return;
    }
    if (isNew) {
      createNewTag();
    } else {
      updateTag();
    }
  };

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      onSubmit(event);
    }
  };

  useEffect(() => {}, []);

  return (
    <AnnotationForm onSubmit={onSubmit} className="pnlm-ignore oco-dialogue" id="annotation-form">
      <textarea
        style={{ width: '100%', resize: 'none', height: '100%' }}
        rows={5}
        onChange={e => setTmpText(e.target.value)}
        defaultValue={isNew ? '' : text}
        onKeyDown={handleKeyDown}
      />
      <em
        style={{ fontSize: '0.75em', textAlign: 'right', fontFamily: 'Roboto', color: '#7E858E' }}>
        Press enter to save
      </em>
    </AnnotationForm>
  );
};

interface IAnnotationProps {
  annotation: any;
  transform: string;
  hydrate: any;
}

export const Annotation = ({ annotation, transform, hydrate }: IAnnotationProps) => {
  const [edit, setEdit] = useState(false);
  const [text, setText] = useState('');

  useEffect(() => {
    if (!annotation?.message) {
      setText('');
      setEdit(true);
    } else {
      setEdit(false);
      setText(annotation.message);
      mixpanel.track('View Annotation');
    }
  }, [annotation]);

  return (
    <AnnotationContainer
      style={{ transform: transform }}
      id="annotation-container"
      className="down-arrow pnlm-ignore oco-dialogue">
      {edit || !annotation ? (
        <AnnotationInput
          isNew={!annotation}
          setEdit={setEdit}
          text={text}
          hydrate={hydrate}
          setText={setText}
        />
      ) : (
        <Flex flexDirection="column" justifySpaceBetween>
          <AnnotationMessage onDoubleClick={() => setEdit(!edit)}>{text}</AnnotationMessage>
          <LastUpdateMessage>
            Edited: <Moment format="MM/DD/YY hh:mm:ss">{annotation?.updated_at}</Moment>
            <br />
            {annotation.last_edited_by.first_name + ' ' + annotation.last_edited_by.last_name}
          </LastUpdateMessage>
        </Flex>
      )}
    </AnnotationContainer>
  );
};
