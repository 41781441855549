import React from 'react';
import styled from 'styled-components';
import iconChecked from '../../assets/images/icon_checked.svg';

interface CheckboxProps {
  checked?: boolean;
  onChange?: (checked: boolean) => void;
  color?: string;
}

export const Checkbox = ({ checked = false, onChange, color = '#073C7A' }: CheckboxProps) => {
  return (
    <CheckboxLabel>
      <HiddenCheckbox
        type="checkbox"
        checked={checked}
        onChange={e => onChange?.(e.target.checked)}
      />
      <StyledCheckmark checked={checked} color={color} />
    </CheckboxLabel>
  );
};

const CheckboxLabel = styled.label`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
`;

const HiddenCheckbox = styled.input`
  display: none;
`;

const StyledCheckmark = styled.div<{ checked: boolean; color: string }>`
  width: 20px;
  height: 20px;
  border-radius: 4px;
  border: 1px solid ${({ color }) => color};
  background-color: ${({ checked, color }) => (checked ? color : '#fff')};
  display: flex;
  justify-content: center;
  align-items: center;

  &::after {
    content: ${({ checked }) => (checked ? `url(${iconChecked})` : "''")};
    position: relative;
    top: -1px;
    display: block;
  }
`;
